'use client'

import Form from "@/components/form/Form"
import { CAMERA } from "@/components/Icons"
import Popup from "@/components/popup/Popup"
import PopupBody from "@/components/popup/PopupBody"
import Spinner from "@/components/Spinner"
import Tabs from "@/components/tabs/Tabs"
import { emitEvent } from "@/plugins/EventListener"
import ApiUtils from "@/utils/ApiUtils"
import { US_STATES } from "@/utils/Constants"
import { isValidZip, replacePlaceholders } from "@/utils/Helpers"
import debounce from "lodash.debounce"
import moment from "moment"
import Image from "next/image"
import { useRouter } from "next/navigation"
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"

const VehicleForm = forwardRef((props,ref) => {

  const tabs = [
    { key: 'vin', className: 'veh-tab', labels: [{ label: 'VIN', className: '' }] },
    { key: 'plate', className: 'veh-tab', labels: [{ label: 'Plate', className: '' }] },
    ...(props.ymmt ? [{ key: 'ymmt', className: 'veh-tab', labels: [{ label: 'Make / Model', className: '' }] }] : []),
  ]

  const router = useRouter()
  const findVinPopup = useRef()

  const legal = props.config.legal

  let formId = "vehicle_form"
  if (props.confirm) formId = "confirm_vehicle_form"
  if (props.update) formId = "update_vehicle_form"
  if (props.ymmt) formId = "ymmt_vehicle_form"

  const captureEmail = (!props.confirm && !props.update) ? true : false

  const [selectedTab,setSelectedTab] = useState(props.tab || "vin")
  const [form,setForm] = useState({
    vin: props.searchParams?.v,
    mileage: props.searchParams?.m,
    zip: props.searchParams?.z,
    promo_code: props.searchParams?.code
  })
  const [error,setError] = useState()
  const [processing,setProcessing] = useState(false)
  const [ymmtTerm,setYmmtTerm] = useState()
  const [ymmtData,setYmmtData] = useState({ years: [], makes: [{ id: 1, name: 'Mazda' }], models: [], trims: [] })
  const [cameraOn,setCameraOn] = useState(false)

  const loaingProducts = props.searchParams?.v && props.searchParams?.m && props.searchParams?.z ? true : false

  // useEffect(() => {
  //   if (ymmtTerm) {
  //     debouncedYmmtSearch()
  //   } else {

  //   }
  // }, [ymmtTerm])

  useEffect(() => {
    if (selectedTab == 'ymmt') loadYmmtData()
  }, [selectedTab, form.vehicle_year,form.vehicle_make,form.vehicle_model,form.vehicle_trim])
  
  // useEffect(() => {
  //   loadYmmtData()
  // }, [form.vehicle_year,form.vehicle_make,form.vehicle_model])

  // useEffect(() => {
  //   if (form.vehicle_year && form.vehicle_make && form.vehicle_model && form.vehicle_trim) decode()
  // }, [form.vehicle_trim])

  useEffect(() => {
    if (props.searchParams?.v && props.searchParams?.m && props.searchParams?.z) {
      decode()
    }
  }, [props.searchParams?.v])

  const onFieldChange = (e, field) => {
    let data = Object.assign({}, form)
    data[field || e.target.name] = e.target.value
    setForm(data)
  }

  const onYmmtFieldChange = (e) => {
    setYmmtTerm(e.target.value)
  }

  const localSearchYmmt = () => {
    ref.current.searchYmmt()
  }

  const searchYmmt = () => {
    // ApiUtils.getRequest("/api/ymmt?q=" + ymmtTerm)
    // .then(data => {
    // })
  }

  const debouncedYmmtSearch = useMemo(() => debounce(localSearchYmmt, 500), [])

  const onSubmit = (e) => {
    e.preventDefault()
    decode()
  }

  const isNotValid = () => {
    let error = false
    error = error || !(selectedTab != "vin" || (selectedTab == 'vin' && form.vin))
    error = error || !(selectedTab != 'plate' || (selectedTab == 'plate' && form.plate && form.state))
    error = error || !form.mileage || !form.zip || !isValidZip(form.zip)
    error = error || (selectedTab == "ymmt" && !form.sample_vin)
    return error
  }

  const decode = () => {
    setProcessing(true)
    let error = isNotValid()
    if (error) {
      setProcessing(false)
      setError("Please enter correct information in all the fields above.")
      return
    }
    let params = Object.assign({}, props.searchParams || {}, {
      type: selectedTab,
      zip: form.zip,
      mileage: form.mileage,
      plate: form.plate,
      state: form.state,
      vin: form.vin,
      promo_code: form.promo_code,
      email: form.email
    })
    if (selectedTab == "ymmt") {
      params["year"] = form.vehicle_year
      params["make_name"] = form.vehicle_make
      params["model_name"] = form.vehicle_model
      params["trim_name"] = form.vehicle_trim
      params["sample_vin"] = form.sample_vin
    }
    if (props.vehicle) {
      params["vehicle_id"] = props.vehicle.vehicle_id
    }
    ApiUtils.getRequest("/v1/vehicles/decode?", params)
    .then(data => {
      if (data.error) {
        setProcessing(false)
        setError("We could not find your vehicle. Please check and try again.")
      } else if (data.brand_not_supported) {
        setProcessing(false)
        setError("Currently we only provide products for " + (data.brands.length == 1 ? data.brands[0] + "." : "on the following brands: " + data.brands.join(", ")))
      } else {
        if (props.confirm) {
          setProcessing(false)
          emitEvent("vehicle_updated")
        } else {
          if (props.config.questions?.list?.length > 0 && !props.searchParams?.v) {
            router.push("/qa/" + data.vehicle.vehicle_id + (props.bundle ? "?b=" + props.bundle.id : ""))
          } else {
            router.push("/products/" + data.vehicle.vehicle_id + (props.bundle ? "?b=" + props.bundle.id : ""))
          }
        }
      }
    })
    .catch(err => {
      setProcessing(false)
    })
  }

  const onTypeChange = (tab) => {
    setSelectedTab(tab.key)
  }

  const loadYmmtData = () => {
    let params = { year: form.vehicle_year, make: form.vehicle_make, model: form.vehicle_model, trim: form.vehicle_trim }
    ApiUtils.getRequest("/v1/ymmt/list", params)
    .then(data => {
      let formData = Object.assign({}, form)
      let tmpData = Object.assign({}, ymmtData)
      if (data.type) {
        tmpData[data.type] = data[data.type]
        setYmmtData(tmpData)
      } else if (data.vin) {
        formData['sample_vin'] = data.vin
      }
      if (data.makes && data.makes.length == 1) formData['vehicle_make'] = data.makes[0].id
      if (data.models && data.models.length == 1) formData['vehicle_model'] = data.models[0].id
      if (data.trims && data.trims.length == 1) formData['vehicle_trim'] = data.trims[0].id
      // if (data.type == "models") {
      //   formData['vehicle_model'] = ""
      //   formData['vehicle_trim'] = ""
      // }
      // if (data.type == "trims") {
      //   formData['vehicle_trim'] = ""
      // }
      // if (data.type == "makes") {
      //   formData['vehicle_make'] = ""
      // }
      setForm(formData)
    })
  }

  useImperativeHandle(ref, () => ({
    debouncedYmmtSearch, searchYmmt
  }))

  const showWhereIsVin = (e) => {
    e.preventDefault()
    findVinPopup.current.show()
  }

  const openCamera = (e) => {
    e.preventDefault()
    setCameraOn(true)
  }

  const handlePhoto = (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append("img", e.target.files[0])
    // alert(JSON.stringify(e.target.files[0]))
    ApiUtils.postMultipart("/v1/vehicles/scan", formData)
    .then(data => {
      if (data.vin) {
        let formData = Object.assign({}, form)
        formData["vin"] = data.vin
        setForm(formData)
      } else {
        let msg = "Sorry we were unable to find a vin in the photo. Please try again by taking a clearer photo."
        if (data.texts) {
          msg += "\n\n" + JSON.stringify(data.texts)
        }
        alert(msg)
      }
    })
  }

  if (loaingProducts) return <></>
  
  return <section>
    { props.message &&
    <section className="centered">
      { props.message }
    </section>
    }
    <section className="centered">
      <Tabs items={tabs} selected={selectedTab} onChange={tab => onTypeChange(tab)}></Tabs>
    </section>
    <section>
      <div className="flex-column">
        <div className="flex-col">
          <Form className="form" id={formId} name="Vehicle Form" onSubmit={e => onSubmit(e)}>
            <div className='form-cells'>
              { selectedTab == 'vin' &&
              <div className='form-cell'>
                <div className="form-with-help">
                  <input type="text" name="vin" value={form.vin || ''} aria-label="VIN Number" placeholder="VIN Number" onChange={e => onFieldChange(e, 'vin')}></input>
                  <div type="button" className="form-icon">
                    { CAMERA }
                    <input type="file" name="vin_photo" aria-label="VIN Photo" onChange={e => handlePhoto(e)}></input>
                  </div>
                </div>
              </div>
              }
              { selectedTab == 'plate' &&
              <div className='form-cell form-cell50'>
                <input type="text" name="plate" value={form.plate || ''} aria-label="Plate Number" placeholder="Plate Number" onChange={e => onFieldChange(e, 'plate')}></input>
              </div>
              }
              { selectedTab == 'plate' &&
              <div className='form-cell form-cell50'>
                <select name="state" value={form.state || ''} aria-label="State" placeholder="State" onChange={e => onFieldChange(e, 'state')}>
                  { US_STATES.map((e,i) => (
                  <option key={i} value={e}>{e || 'State'}</option>
                  ))}
                </select>
              </div>
              }
              { selectedTab == 'ymmt' &&
              <div className='form-cell form-cell50'>
                <select name="vehicle_year" value={form.vehicle_year} aria-label="Search by year" placeholder="Search by year" onChange={e => onFieldChange(e)}>
                  <option value="">Select Year</option>
                  {/* { ymmtData.years.map((e,i) => (
                  <option key={i} value={e}>{e}</option>
                  ))} */}
                  { ymmtData.years.map((e,i) => (
                  <option key={i} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
              }
              { selectedTab == 'ymmt' &&
              <div className='form-cell form-cell50'>
                <select name="vehicle_make" value={form.vehicle_make} aria-label="Search by make" placeholder="Search by make" onChange={e => onFieldChange(e)} disabled={!form.vehicle_year || ymmtData.makes?.length == 0}>
                  <option value="">Select Make</option>
                  { ymmtData.makes.map((e,i) => (
                  <option key={i} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
              }
              { selectedTab == 'ymmt' &&
              <div className='form-cell form-cell50'>
                <select name="vehicle_model" value={form.vehicle_model} aria-label="Search by model" placeholder="Search by model" onChange={e => onFieldChange(e)} disabled={!form.vehicle_make || ymmtData.models?.length == 0}>
                  <option value="">Select Model</option>
                  { ymmtData.models.map((e,i) => (
                  <option key={i} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
              }
              { selectedTab == 'ymmt' &&
              <div className='form-cell form-cell50'>
                <select name="vehicle_trim" value={form.vehicle_trim} aria-label="Search by trim/style" placeholder="Search by trim" onChange={e => onFieldChange(e)} disabled={!form.vehicle_model || ymmtData.trims?.length == 0}>
                  <option value="">Select Trim/Style</option>
                  { ymmtData.trims.map((e,i) => (
                  <option key={i} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
              }
              { !props.confirm &&
              <div className='form-cell form-cell50'>
                <input type="text" name="mileage" value={form.mileage || ''} aria-label="Miles" placeholder="Miles (Odometer)" onChange={e => onFieldChange(e, 'mileage')}></input>
              </div>
              }
              { !props.confirm &&
              <div className='form-cell form-cell50'>
                <input type="text" name="zip" value={form.zip || ''} aria-label="Zip Code" placeholder="Your Zip Code" onChange={e => onFieldChange(e, 'zip')}></input>
              </div>
              }
              { captureEmail &&
              <div className='form-cell'>
                <input type="text" name="email" value={form.email || ''} aria-label="Your Email (optional)" placeholder="Your Email (optional)" onChange={e => onFieldChange(e, 'email')}></input>
              </div>
              }
              { error &&
              <div className='form-cell text-align-center text-red text-bold'>
                { error }
              </div>
              }
              <div className='form-cell'>
                <button type="submit" className={'button button-primary ' + (processing ? 'processing' : '')} disabled={processing || isNotValid()}>
                  <span dangerouslySetInnerHTML={{__html: props.confirm ? 'Confirm' : props.update ? 'Update' : props.buttonLabel || props.page?.texts?.vehicle_button || 'Get Started' }}></span>
                  { processing &&
                  <Spinner></Spinner>
                  }
                </button>
              </div>
              { !props.update &&
              <div className='form-cell'>
                <a className="link-centered" onClick={e => showWhereIsVin(e)}>Where can I find my VIN?</a>
              </div>
              }
            </div>
          </Form>
        </div>
      </div>
    </section>
    { !props.confirm && !props.noDisc && !props.update &&
    <section>
      <div className="disclaimer" dangerouslySetInnerHTML={{__html: replacePlaceholders(legal.general, { config: props.config }) }}>
      </div>
    </section>
    }
    { !props.update &&
    <Popup title="Where can I find my VIN?" ref={findVinPopup} className="find-vin-popup">
      <PopupBody className="find-vin-container">
        <Image src={props.config.features?.find_vin_image || "/cdn/images/find-my-vin.png"} alt={"Find My VIN"} fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "cover" }} priority={false} sizes={'1600x1600'}></Image>
      </PopupBody>
    </Popup>
    }
  </section>

})

VehicleForm.displayName = "VehicleForm"
export default VehicleForm
